import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {
  auth,
  getIdTokenResult,
  signInWithCredential,
  signInWithEmailAndPassword,
  signOut,
  setPersistence,
  indexedDBLocalPersistence,
  GoogleAuthProvider
} from '../../../data/firebase/auth'
import normalizeAuth from '../../../data/firebase/normalizeAuth'
import fetchSeller from '../seller/fetchSeller'
import persistentStore from '../../../data/persistentStore'
import { clearStoredAuth } from '../../../data/db/authDB'

export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password }, { rejectWithValue, dispatch }) => {
    try {
      await setPersistence(auth, indexedDBLocalPersistence)

      const response = await signInWithEmailAndPassword(
        auth,
        email,
        password
      )
      const user = response.user
      const tokenResult = await getIdTokenResult(user)

      persistentStore.setToken(tokenResult.token)

      const userAuth = normalizeAuth({ ...user, ...tokenResult.claims })

      await dispatch(
        fetchSeller({
          slugSeller: userAuth.slugSeller,
          token: tokenResult.token
        })
      ).unwrap()

      return {
        user: userAuth,
        token: tokenResult.token
      }
    } catch (error) {
      return rejectWithValue(JSON.stringify(error?.message))
    }
  }
)

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      await signOut(auth)
      persistentStore.deleteAuth()
      await clearStoredAuth()
      return {}
    } catch (error) {
      return rejectWithValue(JSON.stringify(error))
    }
  }
)

export const loginWithGoogleCredential = createAsyncThunk(
  'auth/loginWithGoogleCredential',
  async (credential, { dispatch, rejectWithValue }) => {
    try {
      const authCredential = GoogleAuthProvider.credential(
        credential.idToken
      )
      const response = await signInWithCredential(auth, authCredential)
      const user = response.user
      const tokenResult = await getIdTokenResult(user)
      const userAuth = normalizeAuth({ ...user, ...tokenResult.claims })

      persistentStore.setToken(tokenResult.token)

      await dispatch(
        fetchSeller({
          slugSeller: userAuth.slugSeller,
          token: tokenResult.token
        })
      ).unwrap()

      return { user: userAuth, token: tokenResult.token }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    user: null,
    token: null,
    loading: true,
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(login.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isAuthenticated = true
        state.user = { ...state.user, ...action.payload.user }
        state.token = action.payload.token
        state.loading = false
        state.error = null
      })
      .addCase(login.rejected, (state, action) => {
        state.error = action.payload
        state.loading = false
      })
      .addCase(loginWithGoogleCredential.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(loginWithGoogleCredential.fulfilled, (state, action) => {
        state.isAuthenticated = true
        state.user = { ...state.user, ...action.payload.user }
        state.token = action.payload.token
        state.loading = false
        state.error = null
      })
      .addCase(loginWithGoogleCredential.rejected, (state, action) => {
        state.error = action.payload
        state.loading = false
      })
      .addCase(logout.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(logout.fulfilled, state => {
        state.isAuthenticated = false
        state.user = null
        state.token = null
        state.loading = false
        state.error = null
      })
      .addCase(logout.rejected, (state, action) => {
        state.error = action.payload
        state.loading = false
      })
  }
})
const reducer = authSlice.reducer
export default reducer
